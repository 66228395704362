.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.multiSelectGlobalFilterPanel.p-multiselect-panel .p-multiselect-header {
  display: none;
}

.assetMultiSelectDropdown.p-multiselect-panel .p-multiselect-header {
  display: none;
}

.multiSelectGlobalFilter.p-multiselect {
  border-color: rgb(218, 220, 224);
  border-radius: 18px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-right: 1%;
  background: #ebf5fc;
  color: #2d4d76;
  font-size: 15px;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2px;
}

.multiSelectGlobalFilter.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight {
  background: white !important;
}

.multiSelectGlobalFilterPanel
  .p-multiselect-items-wrapper
  .p-multiselect-items {
  padding: 5px;
  font-family: "Karla", sans-serif;
  font-size: 15px;
  border-radius: 8px;
}

.multiSelectGlobalFilterPanel .p-checkbox .p-checkbox-box {
  border-radius: 5px;
  width: 18px;
  height: 18px;
}

/* .multiSelectGlobalFilterPanel.p-component,
.p-component * {
  border-radius: 15px;
} */

.multiSelectGlobalFilterPanel
  .p-multiselect-items-wrapper
  .p-multiselect-items
  .p-multiselect-item {
  padding: 0.25rem 0.5rem;
}
.treeSelectFilter.p-treeselect {
  border-color: rgb(218, 220, 224);
  border-radius: 18px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-right: 1%;
  background: #ebf5fc;
  color: #2d4d76;
  font-size: 15px;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2px;
}

.treeSelectFilter .p-tree .p-tree-container .p-treenode {
  padding: 0px;
}

.treeSelectFilterPanel
  .p-treeselect-items-wrapper
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content {
  padding: 1.5px;
  font-family: "Karla", sans-serif;
  font-size: 15px;
  border-radius: 8px;
}

.treeSelectFilterPanel .p-checkbox .p-checkbox-box {
  border-radius: 5px;
  width: 18px;
  height: 18px;
}
.treeSelectFilterPanel
  .p-treeselect-items-wrapper
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler
  .pi {
  font-size: 0.8rem;
}

/* .treeSelectFilterPanel.p-treeselect-items-wrapper.p-component .p-tree-container{
  border-radius: 15px;
} */
.treeSelectFilterPanel
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler {
  display: none;
}

.treeSelectFilterPanel .p-treeselect-panel {
  border-radius: 0px;
}
.treeSelectFilterPanel {
  border-radius: 20px;
}
.assetMgmtMultiSelect.p-multiselect {
  border-color: rgb(218, 220, 224);
  border-radius: 18px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-right: 1%;
  background: #ebf5fc;
  color: #2d4d76;
  font-size: 15px;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2px;
}

.assetMgmtMultiSelect.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight {
  background: white !important;
}

.chipItems.p-chip {
  background: aliceblue;
  border: solid 0.5px #acb8c2;
}

.chipItems.p-chip .p-chip-text {
  line-height: 1 !important;
  font-size: 14px;
}

.inputText.p-inputtext {
  border-color: rgb(218, 220, 224);
  padding-top: 9px;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 100%;
}

.globalFilter.p-multiselect {
  /* min-width: 15rem; */
  border-color: rgb(218, 220, 224);
  border-radius: 30px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-right: 1%;
}

.globalFilter.p-dropdown {
  border-color: rgb(218, 220, 224);
  border-radius: 30px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-right: 1%;
}

.globalFilter.p-dropdown .p-dropdown-label {
  /* background: #2d4d76; */
  background: #ebf5fc;
  color: #2d4d76;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  font-size: 15px;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2px;
}

.globalFilter.p-dropdown .p-dropdown-label.p-placeholder {
  color: #2d4d76;
}

.globalFilter.p-dropdown .p-dropdown-trigger {
  background: #ebf5fc;
  color: #2d4d76;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.globalFilter.p-dropdown-trigger-icon p-clickable pi pi-chevron-down {
  font-size: 14px;
}

.assetGlobalFilter.p-dropdown {
  border: 0px solid white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin-top: 12px;
  width: 100%;
}

.assetMgmtMultiSelect.p-multiselect-panel .p-multiselect-items {
  padding: 0;
  font-family: "Karla", sans-serif;
}

.assetGlobalFilter.p-dropdown .p-dropdown-label {
  /* background: #2d4d76; */
  /* background: #ebf5fc; */
  /* color: #2d4d76; */
  /* border-top-left-radius: 30px; */
  /* border-bottom-left-radius: 30px; */
  /* font-size: 15px; */
  font-family: "Karla", sans-serif;
  /* font-weight: bold; */
  /* letter-spacing: 0.2px; */
}

.assetGlobalFilter.p-dropdown .p-dropdown-trigger {
  /* background: #ebf5fc; */
  /* color: #2d4d76; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.assetGlobalFilter.p-dropdown-trigger-icon p-clickable pi pi-chevron-down {
  font-size: 14px;
}

.multiSelectDropdown.p-multiselect-panel.p-component.p-connected-overlay-enter-done {
  z-index: 3003 !important;
  border-radius: 10px;
}

.multiSelectPanel.p-multiselect {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 4px;
  font-family: "Karla", sans-serif;
}

.multiSelectPanel.p-multiselect.p-multiselect-chip.p-multiselect-token {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
}

.assetMultiSelectPanel.p-multiselect {
  border: solid 0px lightgray;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);

  /* border-radius: 5px; */
  padding: 4px 0px 4px 0px;
  font-family: "Karla", sans-serif;
}

.assetMultiSelectPanel {
  margin: 5px 0px 5px 0px;
}
.assetMultiSelectPanel.p-multiselect.p-multiselect-chip.p-multiselect-token {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
}

.selectButton.p-selectbutton .p-button {
  border-color: rgb(218, 220, 224);
  border-radius: 15px;
  padding: 7px 10px 7px 10px;
  font-size: 15px;
  height: 30px;
}

.selectButton.p-selectbutton .p-button.p-highlight {
  border-color: rgb(218, 220, 224);
  color: #2d4d76;
  background: aliceblue;
  padding: 7px 10px 7px 10px;
  font-size: 15px;
  height: 30px;
}

.splitterPanel.p-splitter-panel {
  flex-basis: calc(70% - 0px);
}

.splitPanel.p-splitter.p-component {
  font-family: "Karla", sans-serif;
}

.tabViewPanel.p-tabview .p-tabview-panels {
  padding: 0px;
}

.p-treeselect-panel .p-treeselect-header {
  display: none;
}

.treeSelectFilter.p-treeselect {
  border-color: rgb(218, 220, 224);
  border-radius: 30px;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  padding: 0px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0px;
}

.quickviewTab .p-tabview-selected {
  /* background-color: blue; */
  border-bottom: 2px solid blue;
}

.quickviewTab.p-tabview {
  height: 100%;
}

.quickviewTab.p-tabview .p-tabview-nav {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.quickviewTab.p-tabview .p-tabview-panels {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid yelow;

  padding: 0px;
}
.quickviewTab.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-top-left-radius: 12px;
}

.dataTable.p-datatable-header {
  background: #f8f9fa;
}

.dataTable.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  /* padding: 3px; */
}

.dataTable.p-datatable .p-datatable-thead > tr > th {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
}

.dataTable.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 12px;
}

.notificationDataTable.p-datatable-header {
  background: #f8f9fa;
}

.notificationDataTable.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  /* padding: 3px; */
}

.notificationDataTable.p-datatable .p-datatable-thead > tr > th {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
}

.notificationDataTable.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 12px;
}

.devMgmtDataTable.p-datatable-header {
  background: #f8f9fa;
}

.devMgmtDataTable.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  /* padding: 3px; */
}

.devMgmtDataTable.p-datatable .p-datatable-thead > tr > th {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
}

.devMgmtDataTable.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 12px;
}

.userMgmtDataTable.p-datatable-header {
  background: #f8f9fa;
}

.userMgmtDataTable.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  /* padding: 3px; */
}

.userMgmtDataTable.p-datatable .p-datatable-thead > tr > th {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
}

.userMgmtDataTable.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 12px;
}

.dataTableAsList.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
}

.dataTableAsList.p-datatable .p-datatable-thead > tr > th {
  word-break: break-word;
  font-family: "Karla", sans-serif;
  font-size: 13px;
}

.dialog.p-dialog .p-dialog-header {
  padding: 15px 5px 10px 5px;
  background: aliceblue;
}

.progressBar.p-progressbar {
  height: 6px;
}

.floatProgressBar.p-progressbar {
  height: 6px;
  position: absolute;
  z-index: 1000;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
}

.imageCarousel.p-carousel {
  width: 100%;
}

.organizationchart.p-organizationchart .p-organizationchart-node-content {
  padding: 0em;
  width: 140px;
  border: 0 none;
}

.organizationchart.p-organizationchart .node-header {
  background-color: #495ebb;
  color: #ffffff;
}

.organizationchart.p-organizationchart .node-content {
  border: 0 none;
  padding: 0em;
}

/* .organizationchart.p-organizationchart .p-organizationchart-line-down {
  color: #454546;
  border-right: 1px solid #9999a0;
  border-color: #454546;
} */

/* .organizationchart.p-organizationchart .p-organizationchart-line-top {
  color: #454546;
  border-right: 1px solid #454546;
  border-color: #9999a0;
} */

/* .organizationchart.p-organizationchart .p-organizationchart-line-left {
  color: #454546;
  border-right: 1px solid #454546;
  border-color: #9999a0;
}

.organizationchart.p-organizationchart .p-organizationchart-line-right {
  color: #454546;
  border-right: 1px solid #454546;
  border-color: #9999a0;
} */

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button,
.p-fileupload-choose.p-button-info {
  color: #ffffff;
  background: #0050b3;
  padding: 6px 6px;
  border: 1px solid #0050b3;
  font-size: 13px;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.2px;
}

.p-menu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  font-size: 13px;
  font-weight: bold;
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  max-width: 150px;
  border: 0 none;
  height: 500px;
  overflow: auto;
}

.p-button.p-button-icon-only {
  width: 30px;
}

.tabview-demo .tabview-header-icon i,
.tabview-demo .tabview-header-icon span {
  vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
  margin: 0 0.5rem;
}

.tabview-demo .p-tabview p {
  line-height: 1.5;
  margin: 0;
}
.deviceFeed-tabOneContent {
  display: flex;
  /* justify-content: space-between; */
  min-height: 60vh;
}

.deviceFeed-googleMap {
  min-height: "100px";
  margin-top: 20px;
  /* min-width: 600px; */
  min-width: 48vw;
  max-width: 50vw;
  border: solid 1px lightgray;
  border-radius: 10;
  background: white;
}

.deviceFeed-tabOnePanel {
  /* margin-right: 75px; */
  font-family: "Karla", sans-serif;
}

.deviceFeed-tabTwoContent {
  margin: 0;
  padding: 0;
}

.gridWidget {
  background: white;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 12;
}

.gridWidget .p-datatable .p-datatable-header {
  padding-bottom: 20px;
}

.notificationTopPadding {
  padding: 25px;
}

.notificationSettingsHeight {
  max-height: 850px;
}

.notificationDataTable {
  height: 450px;
  overflow-y: scroll;
}

.devMgmtDataTable {
  height: 450px;
  overflow-y: scroll;
}

.userMgmtDataTable {
  height: 450px;
  overflow-y: scroll;
}

@media screen and (max-width: 576px) {
  .deviceFeed-tabOnePanel {
    margin-right: 45px;
  }

  .deviceFeed-tabOneContent {
    flex-direction: column;
  }

  .deviceFeed-googleMap {
    /* min-width: 300px; */
    min-width: 100%;
    margin-left: 10px;
  }

  .deviceFeed-tabTwoContent {
    min-width: 900px;
  }

  .dataTable .p-datatable-table {
    min-width: 900px;
  }

  .notificationDataTable .p-datatable-table {
    min-width: 900px;
    /* height: 900px; */
  }

  .notificationDataTable {
    height: 80vh;
    overflow-y: scroll;
  }

  .devMgmtDataTable .p-datatable-table {
    min-width: 900px;
    /* height: 900px; */
  }

  .userMgmtDataTable .p-datatable-table {
    min-width: 900px;
    /* height: 900px; */
  }

  .userMgmtDataTable {
    height: 78vh;
  }
  .gridWidget .p-datatable .p-datatable-header {
    padding: 0px 0px 5px 0px;
  }

  .notificationTopPadding {
    padding: 0px;
  }
}
